<template>
  <v-app>
    <v-container fluid class="white">
      <v-row>
        <div class="mt-5" style="width: 100%">
          <v-col cols="12" md="4" class="d-flex justify-center">
            <v-form
              v-model="valid"
              @submit.prevent="saveChanges"
              style="width: 100%"
            >
              <v-card class="bgcards rounded-xl mx-5" elevation="2">
                <v-card-title class="text-h6">
                  Datos de la cadena
                </v-card-title>
                <v-card-text>
                  <pat-input
                    class="mt-4 mb-4"
                    label="Nombre"
                    placeholder="La Divina"
                    v-model="formData.name"
                    :rules="requiredRule"
                  />
                  <pat-select
                    :disabled="true"
                    class="mt-4 mb-4"
                    label="Giro"
                    :items="businessTypes"
                    v-model="formData.businessType"
                    placeholder="Farmacia"
                  />
                  <pat-input
                    :disabled="true"
                    class="mt-4 mb-4"
                    label="Dirección"
                    placeholder="Valle de Mezquital 20"
                    v-model="formData.address"
                  />
                  <pat-input
                    :disabled="true"
                    class="mt-4 mb-4"
                    label="Número de teléfono"
                    placeholder="55 5555 5555"
                    v-model="formData.phone"
                    :maxlength="10"
                    :isNumber="true"
                    :rules="[rules.required]"
                  />

                  <span class="textlabel--text"
                    >¿Estos datos corresponden a la sucursal
                    {{ formData.numberOfSucursal }}?</span
                  >
                  <v-radio-group
                    readonly
                    v-model="formData.isBranch1"
                    class="mt-4 mb-4"
                    color="purple"
                  >
                    <v-radio label="Sí" value="yes" color="purple"></v-radio>
                    <v-radio label="No" value="no" color="purple"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
              <div class="mt-4 px-5">
                <v-btn
                  class="py-6"
                  :disabled="!valid"
                  color="primary"
                  block
                  @click="saveChanges"
                  rounded
                >
                  Guardar cambios
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { saveBusinessDetails } from "../services/auth";
import { useUserStore } from "@/stores/userStore";

export default {
  name: "chain-data-form",
  data() {
    return {
      valid: false,
      user: {},
      formData: {
        numberOfSucursal: 1,
        name: "La Divina",
        businessType: "Farmacia",
        address: "Valle de Mezquital 20",
        phone: "55 5555 5555",
        isBranch1: "yes",
      },
      businessTypes: ["Farmacia", "Supermercado", "Restaurante"],
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
      },
    };
  },
  computed: {
    // Validaciones
    requiredRule() {
      return [(v) => !!v || "Este campo es obligatorio"];
    },
  },
  methods: {
    async saveChanges() {
      const response = await saveBusinessDetails(
        this.user.Negocio.Giro.id,
        this.formData.name,
        this.user.Negocio.direccion
      );
      if (response.success) {
        this.$router.push("/home");
      } else {
        this.$toast({
          message: "Ocurrió un error al guardar tus datos",
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
  async mounted() {
    const userStore = useUserStore();
    await userStore.getUser();
    this.user = userStore.user;
    this.formData.name = this.user.Negocio.nombre;
    this.formData.businessType = this.user.Negocio.Giro.nombre;
    this.formData.address = this.user.Negocio.direccion;
    this.formData.phone = this.user.telefono;
  },
};
</script>
